import "ant-design-vue/es/modal/style/css";
import _Modal from "ant-design-vue/es/modal";
import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick, createVNode } from 'vue';
import useTablePagination from '@/hooks/pagination';
import Cookies from "js-cookie";
import { useStore } from 'vuex';
import { QuestionCircleOutlined, ExclamationCircleOutlined, ProfileOutlined, PartitionOutlined } from '@ant-design/icons-vue';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { selectJobCascade, selectVerifierSetting, updateVerifierSetting } from '@/api/material';
import { selectDepartmentList, selectPerformanceRule, addOrUpdatePerformanceRule, selectPerformanceTime, setPerformanceTime, setPerformance, selectPerformanceByPagination, selectPerformanceById, updateToPerformance, financeConfirmPerformance, selectHistoryPerformanceInfo } from '@/api/personnel'; //表头

const columns = [{
  title: '主题',
  dataIndex: 'theme'
}, {
  title: '发起人',
  dataIndex: 'applicantName'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '发起时间',
  dataIndex: 'createTime'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const kaoDetailColumns = [{
  title: '主题',
  dataIndex: 'theme',
  slots: {
    customRender: 'theme'
  }
}, {
  title: '内容描述',
  dataIndex: 'contentDescribed',
  slots: {
    customRender: 'contentDescribed'
  }
}, {
  title: '权重',
  dataIndex: 'weight',
  width: '110px',
  slots: {
    customRender: 'weight'
  }
}, {
  title: '得分',
  dataIndex: 'score',
  width: '110px',
  slots: {
    customRender: 'score'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 80,
  slots: {
    customRender: 'operation'
  }
}];
const historyColumns = [{
  title: '主题',
  dataIndex: 'theme',
  slots: {
    customRender: 'theme'
  }
}, {
  title: '内容描述',
  dataIndex: 'contentDescribed',
  slots: {
    customRender: 'contentDescribed'
  }
}, {
  title: '权重',
  dataIndex: 'weight',
  width: '110px',
  slots: {
    customRender: 'weight'
  }
}, {
  title: '得分',
  dataIndex: 'score',
  width: '110px',
  slots: {
    customRender: 'score'
  }
}];
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    ExclamationCircleOutlined,
    uploadImgs,
    ProfileOutlined,
    PartitionOutlined
  },

  setup() {
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    const formRef = ref();
    const formState = reactive({
      theme: null,
      state: null,
      data: []
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([{}]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        theme: formState.theme ? formState.theme : null,
        departmentId: formState.departmentId ? formState.departmentId : null,
        startTime: formState.data.length ? formState.data[0] : null,
        endTime: formState.data.length ? formState.data[1] : null,
        state: formState.state
      };
      loading.value = true;

      try {
        let res = await selectPerformanceByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    }; //部门信息


    const departmentData = ref([]);

    const getDepartmentList = async () => {
      let res = await selectDepartmentList();

      if (res.code === 200) {
        departmentData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.departmentName
            };
            departmentData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //绩效考核


    const performanceVisible = ref(false);
    let performanceName = ref('1');
    let jobsAndrespStr = null;
    let timeFormStateStr = null;

    const setPerformanceMode = () => {
      performanceName.value = '1';
      formStateForModelStr = JSON.stringify(formStateForModel);
      jobsAndrespStr = JSON.stringify(jobsAndresp);
      timeFormStateStr = JSON.stringify(timeFormState);
      performanceVisible.value = true;
    };

    const formRefForModel = ref();
    const formStateForModel = reactive({
      performanceRule: null,
      fileUrl: []
    });
    let formStateForModelStr = null; //获取附件的url

    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formStateForModel.fileUrl = val;
    }; //责任人配置


    const jobsAndresp = reactive({
      personnels: [],
      finances: []
    }); //岗位及人员

    const options = ref([]);

    const getJobCascade = async () => {
      const belongProject = Number(Cookies.get("belongProject"));
      let res = await selectJobCascade({
        belongProject
      });

      if (res.code === 200) {
        // console.log(res)
        // const jobCorporations = res.data.jobCorporations //总公司管理岗集合
        const jobProjects = res.data.jobProjects; //本项目管理岗集合

        options.value = conversion(jobProjects);
      }
    };

    const conversion = val => {
      const arr = [];
      val.forEach(item => {
        let obj = {
          value: item.jobId,
          label: item.jobName,
          children: []
        };

        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            obj.children.push({
              value: `${ixt.uid}-${ixt.belongProject}-${ixt.userName}`,
              label: ixt.userName,
              projectId: ixt.belongProject
            });
          });
        }

        arr.push(obj);
      });
      return arr;
    }; //时间规则配置


    const timeFormRef = ref();
    const timeFormState = reactive({
      performanceId: null,
      performanceTime: null,
      performanceDate: null,
      belongProject: null,
      nodeId: null,
      nodeTime: null,
      nodeDate: null
    }); //提交

    const setLoading = ref(false);

    const handleOk = async () => {
      if (performanceName.value == '1') {
        formRefForModel.value.validate().then(async () => {
          let param = {
            id: formStateForModel.id,
            performanceRule: formStateForModel.performanceRule,
            fileUrl: JSON.stringify(formStateForModel.fileUrl)
          };
          setLoading.value = true;

          try {
            let res = await addOrUpdatePerformanceRule(param);

            if (res.code === 200) {
              kaoInfo.id = res.data;
              formStateForModel.id = res.data;
              formStateForModelStr = JSON.stringify(formStateForModel);

              _message.success(res.message);

              setLoading.value = false;
            } else {
              _message.error(res.message);

              setLoading.value = false;
            }
          } catch (error) {
            setLoading.value = false;
            console.log(error);
          }
        });
      }

      if (performanceName.value == '2') {
        const finances = !jobsAndresp.finances[0].length;
        const personnels = !jobsAndresp.personnels[0].length; // console.log(finances, jobsBool, majorBool)

        if (personnels) {
          return _message.error('人事责任人不能为空！');
        }

        if (finances) {
          return _message.error('财务负责人不能为空！');
        }

        setLoading.value = true;
        const param = {
          type: 15,
          belongProject: Number(Cookies.get("belongProject")),
          finances: [],
          personnels: [],
          itemName: null,
          itemId: null
        };
        param.finances = getObj(jobsAndresp.finances, 0);
        param.personnels = getObj(jobsAndresp.personnels, 0);

        try {
          let res = await updateVerifierSetting(param);

          if (res.code == 200) {
            jobsAndrespStr = JSON.stringify(jobsAndresp);

            _message.success(res.message);

            setLoading.value = false;
          } else {
            _message.error(res.message);

            setLoading.value = false;
          }
        } catch (error) {
          setLoading.value = false;
          console.log(error);
        }
      }

      if (performanceName.value == '3') {
        timeFormRef.value.validate().then(async () => {
          let param = {};
          Object.assign(param, timeFormState);
          setLoading.value = true;

          try {
            let res = await setPerformanceTime(param);

            if (res.code === 200) {
              timeFormStateStr = JSON.stringify(timeFormState);

              _message.success(res.message);

              setLoading.value = false;
            } else {
              _message.error(res.message);

              setLoading.value = false;
            }
          } catch (error) {
            setLoading.value = false;
            console.log(error);
          }
        });
      }
    };

    const getObj = (val, num) => {
      let arrData = [];
      val.forEach(item => {
        // console.log(item)
        let arr = num == 1 ? item.value[1].split('-') : item[1].split('-');
        let obj = {
          professionalId: item.id ? item.id : null,
          professionalName: item.name ? item.name : null,
          projectId: Number(arr[1]),
          idCard: arr[0],
          jobId: num == 1 ? item.value[0] : item[0],
          belongProject: Number(Cookies.get("belongProject")),
          userName: arr[2]
        }; // console.log(obj)

        arrData.push(obj);
      });
      return arrData;
    };

    const handleTab = val => {
      console.log(val);
      const oldActive = performanceName.value;
      console.log(oldActive);

      if (oldActive == 1) {
        if (formStateForModelStr !== JSON.stringify(formStateForModel)) {
          return _Modal.confirm({
            title: '当前页存在未保存信息，确认放弃更改吗?',
            icon: createVNode(ExclamationCircleOutlined),

            onOk() {
              if (val == '2') {
                if (getVerifierSetting()) {
                  performanceName.value = val;
                  Object.assign(formStateForModel, JSON.parse(formStateForModelStr));
                }
              }

              if (val == '3') {
                if (handleTimeConf()) {
                  performanceName.value = val;
                  Object.assign(formStateForModel, JSON.parse(formStateForModelStr));
                }
              }
            },

            onCancel() {},

            class: 'test'
          });
        }
      }

      if (oldActive == 2) {
        if (jobsAndrespStr !== JSON.stringify(jobsAndresp)) {
          return _Modal.confirm({
            title: '当前页存在未保存信息，确认放弃更改吗?',
            icon: createVNode(ExclamationCircleOutlined),

            onOk() {
              if (val == '1') {
                getJxData();
                nextTick(() => {
                  performanceName.value = val;
                  Object.assign(jobsAndresp, JSON.parse(jobsAndrespStr));
                });
              }

              if (val == '3') {
                if (handleTimeConf()) {
                  performanceName.value = val;
                  Object.assign(jobsAndresp, JSON.parse(jobsAndrespStr));
                }
              }
            },

            onCancel() {},

            class: 'test'
          });
        }
      }

      if (oldActive == 3) {
        if (timeFormStateStr !== JSON.stringify(timeFormState)) {
          return _Modal.confirm({
            title: '当前页存在未保存信息，确认放弃更改吗?',
            icon: createVNode(ExclamationCircleOutlined),

            onOk() {
              if (val == '1') {
                getJxData();
                nextTick(() => {
                  performanceName.value = val;
                  Object.assign(timeFormState, JSON.parse(timeFormStateStr));
                });
              }

              if (val == '2') {
                if (getVerifierSetting()) {
                  performanceName.value = val;
                  Object.assign(formStateForModel, JSON.parse(formStateForModelStr));
                }
              }
            },

            onCancel() {},

            class: 'test'
          });
        }
      }

      if (val == '1') {
        getJxData();
        nextTick(() => {
          performanceName.value = val;
        });
      }

      if (val == '2') {
        if (getVerifierSetting()) {
          performanceName.value = val;
        }
      }

      if (val == '3') {
        if (handleTimeConf()) {
          performanceName.value = val;
        }
      }
    };

    const kaoInfo = reactive({
      isOpenPerformance: false,
      openTime: null,
      theme: null,
      id: null
    });

    const getJxData = async () => {
      //v-if="isCompanyAdmin == 2 || isCompanyAdmin == 3" 
      let res = await selectPerformanceRule({
        uid: isCompanyAdmin.value == 2 || isCompanyAdmin.value == 3 ? Cookies.get('uid') : null
      });

      if (res.code === 200) {
        formStateForModel.id = res.data.id;
        formStateForModel.performanceRule = res.data.performanceRule;
        formStateForModel.fileUrl = JSON.parse(res.data.fileUrl);
        kaoInfo.isOpenPerformance = res.data.isOpenPerformance ? true : false;
        kaoInfo.openTime = res.data.openTime;
        kaoInfo.id = res.data.id;
        kaoInfo.theme = res.data.theme;
      } else {
        _message.error(res.message);
      }
    };

    const handleCancel = () => {
      const oldActive = performanceName.value;
      console.log(oldActive);

      if (oldActive == 1) {
        if (formStateForModelStr !== JSON.stringify(formStateForModel)) {
          return _Modal.confirm({
            title: '当前页存在未保存信息，确认放弃更改吗?',
            icon: createVNode(ExclamationCircleOutlined),

            onOk() {
              Object.assign(formStateForModel, JSON.parse(formStateForModelStr));
              performanceVisible.value = false;
            },

            onCancel() {},

            class: 'test'
          });
        }

        Object.assign(formStateForModel, JSON.parse(formStateForModelStr));
        performanceVisible.value = false;
      }

      if (oldActive == 2) {
        if (jobsAndrespStr !== JSON.stringify(jobsAndresp)) {
          return _Modal.confirm({
            title: '当前页存在未保存信息，确认放弃更改吗?',
            icon: createVNode(ExclamationCircleOutlined),

            onOk() {
              Object.assign(jobsAndresp, JSON.parse(jobsAndrespStr));
              performanceVisible.value = false;
            },

            onCancel() {},

            class: 'test'
          });
        }

        Object.assign(jobsAndresp, JSON.parse(jobsAndrespStr));
        performanceVisible.value = false;
      }

      if (oldActive == 3) {
        if (timeFormStateStr !== JSON.stringify(timeFormState)) {
          return _Modal.confirm({
            title: '当前页存在未保存信息，确认放弃更改吗?',
            icon: createVNode(ExclamationCircleOutlined),

            onOk() {
              Object.assign(timeFormState, JSON.parse(timeFormStateStr));
              performanceVisible.value = false;
            },

            onCancel() {},

            class: 'test'
          });
        }

        Object.assign(timeFormState, JSON.parse(timeFormStateStr));
        performanceVisible.value = false;
      }
    }; //查询审批责任人信息


    const getVerifierSetting = () => {
      return new Promise((resolve, reject) => {
        const param = {
          type: 15,
          belongProject: Number(Cookies.get("belongProject")),
          itemId: null
        };
        selectVerifierSetting(param).then(res => {
          jobsAndresp.personnels = getJobData(res.data.personnels);
          jobsAndresp.finances = getJobData(res.data.finances);
          jobsAndrespStr = JSON.stringify(jobsAndresp);
          resolve(true);
        }).catch(error => {
          console.log('查询审批责任人信息', error);
        });
      });
    };

    const getJobData = arr => {
      let respData = [];
      arr.forEach(item => {
        respData.push([item.jobId, `${item.idCard}-${item.projectId}-${item.userName}`]);
      });
      return respData;
    }; //查询时间规则配置


    const handleTimeConf = () => {
      return new Promise((resolve, reject) => {
        const param = {
          type: 15,
          belongProject: Number(Cookies.get("belongProject"))
        };
        selectPerformanceTime(param).then(res => {
          timeFormState.performanceId = res.data.performanceId;
          timeFormState.performanceTime = res.data.performanceTime;
          timeFormState.performanceDate = res.data.performanceDate;
          timeFormState.belongProject = res.data.belongProject;
          timeFormState.nodeId = res.data.nodeId;
          timeFormState.nodeTime = res.data.nodeTime;
          timeFormState.nodeDate = res.data.nodeDate;
          timeFormStateStr = JSON.stringify(timeFormState);
          resolve(true);
        }).catch(error => {
          console.log('查询时间规则配置', error);
        });
      });
    }; //开放考核


    const kaoVisible = ref(false);
    let setTheme = ref('');
    const setKaoLoading = ref(false);

    const handleSwitch = async val => {
      console.log(val);

      if (val) {
        setTheme.value = '';
        kaoVisible.value = true;
      } else {
        let res = await setPerformance({
          id: kaoInfo.id,
          theme: kaoInfo.theme,
          isOpenPerformance: 0
        });

        if (res.code === 200) {
          _message.success(res.message);

          kaoInfo.isOpenPerformance = false;
          kaoInfo.openTime = "";
          kaoInfo.theme = "";
        } else {
          _message.error(res.message);
        }
      }
    };

    const handleCancelKao = () => {
      setTheme.value = '';
      kaoVisible.value = false;
    };

    const handleSetKao = async () => {
      setTheme.value = setTheme.value.replace(/\s*/g, "");

      if (!setTheme.value) {
        _message.error('请输入本次考核主题！');

        return;
      }

      setKaoLoading.value = true;

      try {
        let res = await setPerformance({
          id: kaoInfo.id,
          theme: setTheme.value,
          isOpenPerformance: 1
        });

        if (res.code === 200) {
          _message.success(res.message);

          kaoInfo.isOpenPerformance = true;
          kaoInfo.openTime = res.data.openTime;
          kaoInfo.theme = res.data.theme;
          setKaoLoading.value = false;
          kaoVisible.value = false;
          getJxData();
        } else {
          setKaoLoading.value = false;

          _message.error(res.message);
        }
      } catch (error) {
        setKaoLoading.value = false;
        console.log(error);
      }
    }; //绩效详情


    const kaoDetailVisible = ref(false);
    const kaoDetailState = reactive({
      applyId: null,
      applicantName: null,
      performanceDetails: [],
      hyApproachApprovals: [],
      content: null,
      state: 0,
      belongProject: null,
      updateList: [],
      id: null
    });
    const kaoDetailRef = ref();
    const isDisabledForCl = ref(true);

    const toView = async (id, num) => {
      // kaoDetailVisible.value = true
      let res = await selectPerformanceById({
        id
      });

      if (res.code === 200) {
        Object.assign(kaoDetailState, res.data);

        if (res.data.updateList && res.data.updateList.length) {
          historyTime.value = res.data.updateList[0].createTime;
        }

        kaoDetailState.state = num;

        if (num === 38 && res.data.isDetails) {
          isDisabledForCl.value = false;
        } else {
          isDisabledForCl.value = true;
        }

        kaoDetailVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const handleAdd = () => {
      kaoDetailState.performanceDetails.push({
        theme: null,
        contentDescribed: null,
        weight: null,
        score: null,
        id: null
      });
    };

    const onDelete = index => {
      if (kaoDetailState.performanceDetails.length == 1) {
        _message.error('绩效考核明细不能为空！');

        return;
      }

      kaoDetailState.performanceDetails.splice(index, 1);
    }; //审批


    const setKaoDetailLoading = ref(false);

    const handleUpdateKao = state => {
      kaoDetailRef.value.validate().then(async () => {
        if (!kaoDetailState.content) {
          _message.error('审批意见不能为空！');

          return;
        }

        setKaoDetailLoading.value = true;
        let param = {
          applyId: kaoDetailState.applyId,
          content: kaoDetailState.content,
          belongProject: kaoDetailState.belongProject,
          performanceDetails: kaoDetailState.performanceDetails,
          state
        };
        let res = await updateToPerformance(param);

        if (res.code === 200) {
          _message.success(res.message);

          setKaoDetailLoading.value = false;
          kaoDetailVisible.value = false;
          getTableData();
        } else {
          _message.error(res.message);

          setKaoDetailLoading.value = false;
        }
      });
    };

    const handleCancelKaoDetail = () => {
      kaoDetailState.content = "";
      kaoDetailVisible.value = false;
    }; //确认


    const handleOkKaoDetail = async () => {
      let res = await financeConfirmPerformance({
        applyId: kaoDetailState.applyId
      });

      if (res.code === 200) {
        _message.success(res.message);

        setKaoDetailLoading.value = false;
        kaoDetailVisible.value = false;
        getTableData();
      } else {
        _message.error(res.message);

        setKaoDetailLoading.value = false;
      }
    }; //总分


    const weightSum = computed(() => {
      let sum = 0;

      if (kaoDetailState.performanceDetails.length) {
        kaoDetailState.performanceDetails.forEach(item => {
          if (typeof item.weight === 'number') {
            sum += item.weight;
          }
        });
      }

      return sum;
    });
    const scoreSum = computed(() => {
      let sum = 0;

      if (kaoDetailState.performanceDetails.length) {
        kaoDetailState.performanceDetails.forEach(item => {
          if (typeof item.score === 'number') {
            sum += item.score;
          }
        });
      }

      return sum;
    }); //查看历史

    const historyVisible = ref(false);

    const handleHistory = () => {
      historyVisible.value = true;
      getHistoryData();
    };

    const historyData = reactive({
      tableData: [],
      weightSum: 0,
      scoreSum: 0
    });
    const historyTime = ref('');

    const handleChangeHistoryTime = () => {
      getHistoryData();
    };

    const getHistoryData = async () => {
      historyData.tableData = [];
      historyData.weightSum = 0;
      historyData.scoreSum = 0;
      let res = await selectHistoryPerformanceInfo({
        performanceId: kaoDetailState.id,
        createTime: historyTime.value
      });

      if (res.code === 200) {
        if (res.data && res.data.length) {
          res.data.forEach(item => {
            historyData.tableData.push(item);
            historyData.weightSum += item.weight;
            historyData.scoreSum += item.score;
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getTableData();
      getDepartmentList();
      getJobCascade();
      getJxData();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      toView,
      isCompanyAdmin,
      setPerformanceMode,
      performanceVisible,
      performanceName,
      formRefForModel,
      formStateForModel,
      getAdjuncts,
      options,
      jobsAndresp,
      timeFormState,
      timeFormRef,
      handleOk,
      handleTab,
      kaoInfo,
      handleCancel,
      setLoading,
      handleSwitch,
      kaoVisible,
      setTheme,
      handleCancelKao,
      setKaoLoading,
      handleSetKao,
      kaoDetailVisible,
      kaoDetailState,
      kaoDetailRef,
      kaoDetailColumns,
      isDisabledForCl,
      handleAdd,
      onDelete,
      handleCancelKaoDetail,
      handleUpdateKao,
      setKaoDetailLoading,
      handleOkKaoDetail,
      departmentData,
      weightSum,
      scoreSum,
      handleHistory,
      historyVisible,
      historyData,
      historyTime,
      handleChangeHistoryTime,
      historyColumns
    };
  }

});